export default [
  {
    title: '字段名称',
    width: 220,
    property: 'field_label_name',
  },
  {
    title: '原始值',
    'min-width': 200,
    property: 'before_value',
  },
  {
    title: '更新值',
    'min-width': 200,
    property: 'after_value',
  },
  {
    title: '最终值',
    'min-width': 200,
    property: 'final_value',
  },
];
