export default [
  {
    title: '医院级别',
    property: 'hospital_level',
    value: [
      { code: '0', label: '未知未知(空)' },
      { code: '1', label: '未知甲等' },
      { code: '2', label: '未知乙等' },
      { code: '3', label: '未知丙等' },
      { code: '4', label: '未知未定级' },
      { code: '10', label: '一级未知' },
      { code: '11', label: '一级甲等' },
      { code: '12', label: '一级乙等' },
      { code: '13', label: '一级丙等' },
      { code: '14', label: '一级未定等' },
      { code: '20', label: '二级未知' },
      { code: '21', label: '二级甲等' },
      { code: '22', label: '二级乙等' },
      { code: '23', label: '二级丙等' },
      { code: '24', label: '二级未定等' },
      { code: '30', label: '三级未知' },
      { code: '31', label: '三级甲等' },
      { code: '32', label: '三级乙等' },
      { code: '33', label: '三级丙等' },
      { code: '34', label: '三级未定等' },
      { code: '40', label: '未定级未知' },
      { code: '41', label: '未定级甲等' },
      { code: '42', label: '未定级乙等' },
      { code: '43', label: '未定级丙等' },
      { code: '44', label: '未定级未定等' },
    ],
  },
  {
    title: '所有制性质',
    property: 'ownership',
    value: [
      { code: '0', label: '空' },
      { code: '1', label: '公立' },
      { code: '2', label: '私立' },
    ],
  },
  {
    title: '是否医保',
    property: 'is_medical_insurance',
    value: [
      { code: '2', label: '否' },
      { code: '1', label: '是' },
      { code: '0', label: '不适用/未知' },
    ],
  },
  {
    title: '经营状态',
    property: 'business_status',
    value: [
      { code: '0', label: '暂无' },
      { code: '1', label: '在业' },
      { code: '2', label: '存续' },
      { code: '3', label: '筹建' },
      { code: '4', label: '清算' },
      { code: '5', label: '迁入' },
      { code: '6', label: '迁出' },
      { code: '7', label: '停业' },
      { code: '8', label: '撤销' },
      { code: '9', label: '吊销' },
      { code: '10', label: '注销' },
      { code: '11', label: '正常' },
    ],
  },
  {
    title: '是否存疑',
    property: 'in_doubt',
    value: [
      { code: '1', label: '存疑' },
      { code: '2', label: '不存疑' },
    ],
  },
  {
    title: '数据来源',
    property: 'source',
    value: [
      { code: '0', label: '默认' },
      { code: '1', label: '企查查' },
      { code: '2', label: '天眼查' },
      { code: '3', label: '高德地图' },
      { code: '4', label: '腾讯地图' },
      { code: '5', label: '百度地图' },
      { code: '6', label: '医院' },
      { code: '7', label: '卫健委' },
      { code: '8', label: 'MedMD管理端创建' },
    ],
  },
  {
    title: '医院营利性质',
    property: 'hospital_profit_type',
    value: [
      { code: '0', label: '未选择' },
      { code: '1', label: '营利性' },
      { code: '2', label: '非营利性' },
    ],
  },
];
