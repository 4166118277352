<template>
  <div class="dcr_detail_warp">
    <!-- 标题 -->
    <div class="dcr_detail_warp_top">
      <div class="dcr_detail_title">{{ dcr_code }}-DCR详情</div>
      <div class="dcr_detail_top_btn">
        <el-button
          class="btn_verify"
          type="primary"
          :disabled="
            detailData.status === 3 ||
              (detailData.status === 2 && detailData.verification === 1) ||
              (detailData.status === 2 && detailData.verification === 2)
          "
          @click="verifyDialog = true"
          v-if="systemEnv === '2' && isV && third"
        >
          提交验证申请
        </el-button>
        <el-button
          class="btn_confirm"
          type="primary"
          @click="dcrDialog = true"
          :disabled="
            detailData.status === 3 || (detailData.status === 2 && detailData.verification === 1)
          "
          v-if="systemEnv === '2' && role === 1"
        >
          确认DCR
        </el-button>
        <el-button
          class="btn_confirm"
          type="primary"
          :disabled="detailData.status === 3"
          @click="dcrDialog = true"
          v-if="systemEnv === '1' && role === 1"
        >
          确认DCR
        </el-button>
        <el-button
          class="btn_confirm"
          plain
          @click="rejectDcrDialog = true"
          :disabled="detailData.status === 3"
          v-if="systemEnv === '1' && role === 1"
        >
          拒绝DCR
        </el-button>
        <el-button
          class="btn_confirm"
          plain
          @click="rejectDcrDialog = true"
          :disabled="
            detailData.status === 3 || (detailData.status === 2 && detailData.verification === 1)
          "
          v-if="systemEnv === '2' && role === 1"
        >
          拒绝DCR
        </el-button>
      </div>
    </div>

    <!-- 详细信息 -->
    <div class="box dcr_detail_info">
      <div class="floor_top">
        <el-steps :active="activeStep">
          <el-step title="已提交" :description="stepDesc1" class="step-sty"></el-step>
          <el-step
            title="处理中"
            :description="stepDesc2"
            :class="{ 'step-sty': activeStep === 2 || activeStep === 3 }"
          ></el-step>
          <el-step
            title="已完成"
            :description="stepDesc3"
            :class="{ 'step-sty': activeStep === 3 }"
          ></el-step>
        </el-steps>
      </div>
      <div class="infomations">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="infomation_dcr_data tag1">
              <p>DCR数据:</p>
              <p @click="gotoOriginalPage">{{ record_info.dcr_data }}</p>
            </div>
            <div class="infomation_org_name tag1 tag_mt16" v-if="record_info.type === 'hcp'">
              <p>所属机构:</p>
              <p>{{ record_info.org_name }}</p>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="infomation_dcr_data_type tag1">
              <p>数据类型:</p>
              <p>{{ record_info.dcr_data_type }}</p>
            </div>
            <div class="infomation_position tag1 tag_mt16" v-if="record_info.type === 'hcp'">
              <p>所属地域:</p>
              <p>{{ record_info.position }}</p>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="infomation_dcr_type tag1">
              <p>DCR类型:</p>
              <p>{{ record_info.dcr_type }}</p>
            </div>
            <div class="infomation_section tag1 tag_mt16" v-if="record_info.type === 'hcp'">
              <p>所属科室:</p>
              <p>{{ record_info.section }}</p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="showMoreInfo">
          <el-col :span="8">
            <div class="infomation_create_user tag1 tag_mt16">
              <p>创建人:</p>
              <p>{{ record_info.create_user }}</p>
            </div>
            <div class="infomation_create_desc tag1 tag_mt16">
              <p>创建说明:</p>
              <p>{{ record_info.create_desc }}</p>
            </div>
            <div class="infomation_result_desc tag2 tag_mt16">
              <p>处理结果说明:</p>
              <template v-if="!editTag.result_desc">
                <p :class="{ result_desc_text: record_info.result_desc !== '-' }">
                  {{ record_info.result_desc }}
                </p>
                <i class="el-icon-edit" @click="editTag.result_desc = true" />
              </template>
              <template v-else>
                <el-input
                  class="result_desc_editInput"
                  v-model="result_desc_editVal"
                  placeholder="请输入内容"
                ></el-input>
                <div class="result_desc_editBtn">
                  <i class="el-icon-circle-check" @click="comfirmEditTag('result_desc')"></i>
                  <i class="el-icon-circle-close" @click="closeEditTag('result_desc')"></i>
                </div>
              </template>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="infomation_create_time tag1 tag_mt16">
              <p>创建时间:</p>
              <p>{{ record_info.create_time }}</p>
            </div>
            <div class="infomation_end_user tag1 tag_mt16">
              <p>完成人:</p>
              <p>{{ record_info.end_user }}</p>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="infomation_result tag1 tag_mt16">
              <p>更新状态:</p>
              <p>{{ record_info.database_status }}</p>
            </div>
            <div class="infomation_end_time tag1 tag_mt16">
              <p>完成时间:</p>
              <p>{{ record_info.end_time }}</p>
            </div>
          </el-col>
        </el-row>
        <div class="moreBtn">
          <div class="show-more" v-if="!showMoreInfo" @click="showMoreInfo = true">
            <i class="el-icon-arrow-down"></i>
            查看更多
          </div>
          <div class="put-away" v-if="showMoreInfo" @click="showMoreInfo = false">
            <i class="el-icon-arrow-up"></i>
            收起
          </div>
        </div>
      </div>
    </div>

    <!-- DCR修改明细 -->
    <div class="box dcr_detail_content">
      <div class="dcr_detail_content_top">
        <p>DCR修改明细</p>
        <el-tooltip
          v-if="role === 1 && detailData.type === 3"
          class="item"
          effect="dark"
          content="该类型DCR不可进行内容明细编辑，您可选择确认或拒绝"
          placement="top"
        >
          <!-- <el-button
            plain
            class="editDCR"
            style="color: #96c3fa"
            v-if="role === 1 && detailData.type === 3"
          >
            编辑DCR
          </el-button> -->
          <el-button class="editDCR">编辑DCR</el-button>
        </el-tooltip>
        <el-button
          plain
          class="editDCR"
          @click="handleEditDcr"
          :disabled="
            detailData.status === 3 ||
              (detailData.status === 2 &&
                (detailData.verification === 1 || detailData.verification === 2))
          "
          v-if="role === 1 && detailData.type !== 3"
        >
          编辑DCR
        </el-button>
      </div>
      <div class="dcr_detail_content_data" v-show="!requestData">
        <el-table
          v-show="!requestData"
          :data="tableData"
          :header-cell-style="headercellstyle"
          :header-row-style="{ height: '46px', color: '#667082' }"
          :row-style="{ height: '46px' }"
          :cell-style="{ padding: '0px' }"
          :height="tableHei"
          style="width: 100%"
        >
          <el-table-column
            v-for="column in configList"
            :key="`${$route.name}-${column.property}`"
            :label="column.title"
            :prop="column.property"
            :width="column.width"
          >
            <template slot-scope="scope">
              <template v-if="column.property === 'final_value'">
                <span :class="{ 'modify-color': scope.row.is_modify_final === 1 }">
                  {{ scope.row[column.property] }}
                </span>
              </template>
              <template v-else>{{ scope.row[column.property] }}</template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="totalNum === 0 && !requestData" class="search-no-data">
        <img
          src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
          alt="未查询到相关数据"
        />
        未查询到相关数据
      </div>
      <div class="listLoading" v-if="requestData">
        <img src="@/assets/loading.gif" alt="loading" />
      </div>
    </div>

    <!-- 提交验证弹窗 -->
    <el-dialog title="确认删除吗" :visible.sync="verifyDialog" width="400px">
      <div
        slot="title"
        style="fontsize: '14px'; color: '#1C2028'; display: flex; align-items: center"
      >
        <img src="./img/info.svg" alt="" />
        <p style="margin-left: 9px; font-weight: 500; color: #1c2028">提交DCR验证</p>
      </div>
      <p>您确认需要由专业机构协助验证当前DCR？</p>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-btn dialog-footer-cancelBtn" @click="verifyDialog = false">
          取消
        </el-button>
        <el-button class="dialog-footer-btn" type="primary" @click="handleConfirmVerify">
          确定
        </el-button>
      </div>
    </el-dialog>

    <!-- DCR确认弹窗 -->
    <el-dialog :visible.sync="dcrDialog" width="400px" class="deleteDataModel">
      <span slot="title" style="font-weight: 500; color: #1c2028">确认DCR</span>
      <p class="trip">当前DCR处理结果将被接受并进行数据更新。</p>
      <p class="process">处理结果反馈</p>
      <el-input
        style="width:360px;height:64px"
        type="textarea"
        v-model="reason"
        placeholder="请填写处理结果反馈"
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-btn dialog-footer-cancelBtn" @click="handleDcrCancle">
          取消
        </el-button>
        <el-button
          v-if="systemEnv === '1'"
          class="dialog-footer-btn2 dialog-footer-cancelBtn"
          @click="handleComfirmDCRreplace"
        >
          确定并更新行业库
        </el-button>
        <el-button class="dialog-footer-btn" type="primary" @click="handleComfirmDCR">
          确定
        </el-button>
      </div>
    </el-dialog>

    <!-- 拒绝DCR弹窗 -->
    <el-dialog :visible.sync="rejectDcrDialog" width="400px" class="deleteDataModel">
      <span slot="title" style="font-weight: 500; color: #1c2028">拒绝DCR</span>
      <p class="trip">当前DCR处理结果将被拒绝并进行数据更新。</p>
      <p class="process">处理结果反馈</p>
      <el-input
        type="textarea"
        style="width:360px;height:64px"
        v-model="rejectReason"
        placeholder="请填写处理结果反馈"
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-btn dialog-footer-cancelBtn" @click="handleDcrRe">
          取消
        </el-button>
        <el-button class="dialog-footer-btn" type="primary" @click="handleRejectDCR">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import eyaoTable from '@/components/table';
import configList from './config';
import { QueryDcrDetail, QueryDcrDetails, ExamineDcr, AssistVerify, IsVip } from '@/api/dcr';
import { dateFormat, uniqueArr } from '@/utils/util';
import { ORG_TYPE } from '@/utils/constant';
import hospitalLabel from '../data-list/labelConfig/hospital';
import doctorLabel from '../data-list/labelConfig/doctor';
import { GetConfig } from '@/api/dcr-config';
import LicenseInfo from '../data-detail/config/license';

export default {
  components: {
    // eyaoTable,
  },

  data() {
    return {
      license: [
        {
          name: '111',
          license_code: '123',
          certification_date: '2021',
          expire_date: '2022',
          issuing_authority: '4564',
        },
      ],
      isOpenDcr: false,
      dcrRole: 1,
      third: false,

      isV: null,
      detailData: {},
      rejectDcrDialog: false,
      reason: '',
      rejectReason: '',
      dcrDialog: false,
      verifyDialog: false,
      // 步骤条
      activeStep: 1,
      stepDesc1: '',
      stepDesc2: '',
      stepDesc3: '',
      dcr_code: '',
      record_info: {
        type: 'hco',
        dcr_type: '-', // DCR类型
        dcr_data_type: '-', // 数据类型
        dcr_data: '-', // DCR数据
        org_name: '-', // 所属机构
        position: '-', // 地域
        section: '-', // 所属科室
        create_user: '-',
        create_time: '-',
        create_desc: '-',
        end_user: '-',
        end_time: '-',
        result_desc: '-', // 处理结果说明
        database_status: '-', // 数据库更新状态
      },
      // 编辑标签info
      editTag: {
        result_desc: false,
      },
      // 标签的编辑值
      result_desc_editVal: '',
      // 是否展示更多信息
      showMoreInfo: false,
      requestData: true,
      // 表格数据
      tableData: [],
      totalNum: 0,
      configList: [],
      configLabel: [],
      currentDataPage: 1,
      pageDataSize: 10,
      propertyName: [
        'hospital_level',
        'ownership',
        'is_medical_insurance',
        'business_status',
        'in_doubt',
        'source',
        'gender',
        'hcp_type',
        'hcp_status',
        'professional_title',
        'org_type',
        'administrative_title',
        'academic_title',
        'hospital_profit_type',
      ],
      headercellstyle: {
        background: '#F2F3F8',
        'box-shadow': 'inset 0px -1px 0px #E5EAF2',
        padding: 0,
      },
    };
  },
  computed: {
    // 角色权限判定
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }
      return newRole;
    },
    // 判断管理端还是客户端
    systemEnv() {
      // systemEnv为1表示管理端，2表示客户端
      return localStorage.getItem('systemEnv');
    },
    tableHei() {
      if (this.totalNum > 0 && this.totalNum <= 5) {
        return 230;
      }
      if (this.totalNum > 5 && this.totalNum <= 10) {
        return 300;
      }
      if (this.totalNum > 10 && this.totalNum <= 20) {
        return 400;
      }
      if (this.totalNum > 20) {
        return 500;
      }
      return 100;
    },
    liceseKey() {
      const obj = {};
      LicenseInfo.forEach(item => {
        obj[item.property] = item.title;
      });
      return obj;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'dcrRecord-detail') {
        this.init();
      }
    },
    role(newval) {
      if (newval === 3) {
        this.$message.error('无权限进入');
        this.$router.push('/dashboard/home');
      }
    },
  },
  created() {
    this.getConfig();
  },
  mounted() {
    this.init();
  },
  methods: {
    getConfig() {
      GetConfig({})
        .then(res => {
          console.log(res);
          this.isOpenDcr = res.config.if_open_dcr;
          this.third = res.config.if_optv;
          this.dcrRole = res.config.dcr_role;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    init() {
      this.requestData = true;
      this.configList = configList;
      this.configLabel = uniqueArr([...hospitalLabel, ...doctorLabel], 'property');
      if (this.systemEnv === '2' && this.role === 1) {
        this.getVip();
      }
      this.dealTagQuery();
      this.getDcrDetailList();
    },

    getVip() {
      IsVip({ service_name: 'dcr' })
        .then(res => {
          this.isV = res.is_vip;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },

    dealTagQuery() {
      QueryDcrDetail({ id: this.$route.query.id }).then(res => {
        console.log(res);
        if (res?.data) {
          const resData = JSON.parse(res.data);
          this.detailData = JSON.parse(res.data);
          console.log(this.detailData);
          this.dcr_code = resData.dcr_code;
          const tagQuery = {
            type: resData.data_type === 1 ? 'hcp' : 'hco',
            dcr_type: resData.type || '-', // DCR类型
            dcr_data_type: resData.data_type === 1 ? '医疗专业人士' : '医疗机构' || '-', // 数据类型
            dcr_data: resData.name || '-', // DCR数据
            org_name: resData.hcp_hco_name || '-', // 所属机构
            position: resData.hcp_city_name || '-', // 地域
            section: resData.hcp_dept_name || '-', // 所属科室
            create_user: resData.create_user || '-',
            create_time: this.formatTime(resData.create_time) || '-',
            create_desc: resData.notes || '-',
            end_user: resData.completed_user || '-',
            end_time: this.formatTime(resData.completed_time) || '-',
            result_desc: resData.resolution_notes || '-', // 处理结果说明
            database_status: resData.sync === 1 ? '已同步' : '未同步' || '-', // 数据库更新状态
          };
          this.record_info = tagQuery;
          this.result_desc_editVal = tagQuery.result_desc;
          const dcrType = ['新建', '修改', '删除(失效)'];
          this.record_info.dcr_type = dcrType[tagQuery.dcr_type - 1];
        }
        if (res?.log_data) {
          const logData = res.log_data.map(item => JSON.parse(item));
          // this.showDealStep(logData);
          this.showStep(logData);
        }
      });
    },

    // 状态步骤条的逻辑
    showStep(logs) {
      const { status, verification, resolution, verify_result: verifyRes } = this.detailData;
      const arr2 = ['同意', '拒绝', '未知'];
      const result2 = arr2[verifyRes - 1];
      if (status === 1) {
        this.activeStep = 1;
        this.stepDesc1 = dateFormat(new Date(logs[0].create_time), 'YYYY-MM-dd');
      }
      if (status === 2) {
        this.activeStep = 2;
        this.stepDesc1 = dateFormat(new Date(logs[0].create_time), 'YYYY-MM-dd');
        this.status2Desc(logs, verification, result2);
      }
      if (status === 3) {
        const arr3 = ['同意', '拒绝', '尚未处理'];
        const result3 = arr3[resolution - 1];
        this.activeStep = 3;
        this.stepDesc3 = `【${result3}】${dateFormat(
          new Date(logs[logs.length - 1].update_time),
          'YYYY-MM-dd',
        )}`;
        this.stepDesc1 = dateFormat(new Date(logs[0].create_time), 'YYYY-MM-dd');
        // 情况1、log2条表示管理员直接审批同意/拒绝，stepDesc2的时间和已完成时间一致
        if (logs.length === 2) {
          this.stepDesc2 = dateFormat(new Date(logs[1].update_time), 'YYYY-MM-dd');
        }
        // 情况2，Log条数>2，表示管理员进一步审批dcr
        if (logs.length > 2) {
          this.getStatusMore(logs[logs.length - 2], result2);
        }
      }
    },

    status2Desc(logs, verification, res) {
      switch (verification) {
        case 3:
          this.stepDesc2 = dateFormat(new Date(logs[logs.length - 1].update_time), 'YYYY-MM-dd');
          break;
        case 1:
          this.stepDesc2 = `【已提交验证】${dateFormat(
            new Date(logs[logs.length - 1].update_time),
            'YYYY-MM-dd',
          )}`;
          break;
        case 2:
          this.stepDesc2 = `【三方已验证】${res} ${dateFormat(
            new Date(logs[logs.length - 1].update_time),
            'YYYY-MM-dd',
          )}`;
          break;
      }
    },

    getStatusMore(log, result) {
      switch (log.after_status) {
        case 2:
          this.stepDesc2 = dateFormat(new Date(log.update_time), 'YYYY-MM-dd');
          break;
        case 3:
          this.stepDesc2 = `【已提交验证】${dateFormat(new Date(log.update_time), 'YYYY-MM-dd')}`;
          break;
        case 4:
          this.stepDesc2 = `【三方已验证】${result} ${dateFormat(
            new Date(log.update_time),
            'YYYY-MM-dd',
          )}`;
          break;
      }
    },

    showDealStep(log) {
      console.log(log);
      const arr = ['同意', '拒绝', '尚未处理'];
      const result = arr[this.detailData.resolution - 1];

      // 1-待处理，2-处理中，3-处理中待验证，4-处理中待确认，5-已完成，6-无
      if (log.length === 1) {
        this.activeStep = 1;
        this.stepDesc1 = dateFormat(new Date(log[0].create_time), 'YYYY-MM-dd');
      }
      if (log.length === 2) {
        this.stepDesc1 = dateFormat(new Date(log[0].create_time), 'YYYY-MM-dd');
        // 情况一：管理员对于提交的dcr直接同意或者拒绝，处理中的时间和已完成保持一致
        if (log[0].after_status === 1 && log[1].after_status === 5) {
          this.activeStep = 3;
          this.stepDesc2 = dateFormat(new Date(log[1].update_time), 'YYYY-MM-dd');
          this.stepDesc3 = `【${result}】${dateFormat(new Date(log[1].update_time), 'YYYY-MM-dd')}`;
        } else {
          this.activeStep = 2;
          this.statusDesc2(log[1], result);
        }
      }
      if (log.length === 3) {
        this.stepDesc1 = dateFormat(new Date(log[0].create_time), 'YYYY-MM-dd');
        this.statusDesc2(log[1], result);
        this.activeStep = 3;
        this.stepDesc3 = `【${result}】${dateFormat(new Date(log[2].update_time), 'YYYY-MM-dd')}`;
      }
    },

    // 获取dcr数据明细
    getDcrDetailList() {
      this.requestData = true;
      const params = {
        id: this.$route.query.id,
        index: 0,
        page_size: 0,
      };
      QueryDcrDetails(params)
        .then(res => {
          console.log(res);
          if (res?.data) {
            const arr = [
              'hco_code',
              'hcp_code',
              'parent_id',
              'hco_id',
              'hco_type',
              'hco_type1',
              'hco_type2',
              'hco_type3',
              'hco_type4',
              'hco_type5',
              'province_code',
              'city_code',
              'district_code',
              'town_code',
              'village_code',
              'standard_dept_id',
              'standard_dept1',
              'standard_dept2',
              'standard_dept3',
            ];
            const dataList = res.data.filter(item => !arr.includes(item.field_name));
            this.dealDataLabel(dataList);
            dataList.forEach(item => {
              if (item.afterVal) {
                item.after_value = item.afterVal;
              }
              if (item.finalVal) {
                item.final_value = item.finalVal;
              }
              if (item.beforeVal) {
                item.before_value = item.beforeVal;
              }
            });

            this.tableData = dataList.map(item => {
              if (item.field_label_name === '证照信息') {
                return {
                  ...item,
                  before_value: this.getLicenseKey(item.before_value),
                  after_value: this.getLicenseKey(item.after_value),
                  final_value: this.getLicenseKey(item.final_value),
                };
              }
              return item;
            });
            console.log(dataList, 'dataList明细');
            this.totalNum = dataList.length;
            setTimeout(() => {
              this.requestData = false;
            }, 500);
          }
        })
        .catch(err => {
          this.$message.error(`${err.message}`);
          this.requestData = false;
        });
    },
    getLicenseKey(val) {
      if (val !== '') {
        const str = [];
        JSON.parse(val).forEach(item => {
          const str2 = [];
          for (const key in item) {
            if (key !== 'id') {
              str2.push(`(${this.liceseKey[key]}：${item[key]})`);
            }
          }
          str.push(`{${str2.join(',')}` + '}');
        });
        return str.join(',');
      }
    },

    // 确认编辑标签信息
    comfirmEditTag(tab) {
      this.editTag[tab] = false;
      if (tab === 'result_desc') {
        this.record_info.result_desc = this.result_desc_editVal;
      }
    },
    // 取消编辑标签信息
    closeEditTag(tab) {
      this.editTag[tab] = false;
      if (tab === 'result_desc') {
        this.result_desc_editVal = this.record_info.result_desc;
      }
    },

    // 跳转原始页面-数据中心详情页
    gotoOriginalPage() {
      if (this.detailData.type === 3) return;
      const arr = ['新建', '修改'];
      const dcrType = arr[this.detailData.type - 1];
      console.log(dcrType);
      // this.g_tourl(
      //   `/dashboard/data-detail?editDCR=${dcrType}&dcrId=${this.$route.query.id}&source=${
      //     ORG_TYPE[this.detailData.data_detail_type]
      //   }&viewType=preview`,
      // );
    },

    // 跳转编辑DCR
    handleEditDcr() {
      this.g_tourl(
        `/dashboard/data-detail?editDCR=${this.detailData.type === 1 ? '新建' : '修改'}&dcrId=${
          this.$route.query.id
        }&source=${ORG_TYPE[this.detailData.data_detail_type]}&dataId=${this.detailData.data_id}`,
      );
    },
    // 取消拒绝弹窗
    handleDcrRe() {
      (this.rejectDcrDialog = false), (this.rejectReason = '');
    },
    // 处理拒绝DCR逻辑
    handleRejectDCR() {
      if (!this.rejectReason) {
        this.$message.error('请填写处理结果反馈');
        return false;
      }
      const req = {
        id: this.$route.query.id,
        option: 3,
        comment: this.rejectReason,
      };
      ExamineDcr(req)
        .then(() => {
          this.$message.success('拒绝成功');
          this.rejectDcrDialog = false;
          this.dealTagQuery();
          this.getDcrDetailList();
        })
        .catch(err => {
          this.$message.error(err.message);
          this.rejectReason = '';
        });
    },
    // 处理确认并更新DCR
    handleComfirmDCRreplace() {
      if (!this.reason) {
        this.$message.error('请填写处理结果反馈');
        return false;
      }
      const req = {
        id: this.$route.query.id,
        option: 1,
        comment: this.reason,
      };
      ExamineDcr(req)
        .then(() => {
          this.$message.success('确认成功');
          this.dcrDialog = false;
          this.dealTagQuery();
          this.getDcrDetailList();
        })
        .catch(err => {
          this.$message.error(err.message);
          this.reason = '';
        });
    },
    // 取消确认弹窗
    handleDcrCancle() {
      (this.dcrDialog = false), (this.reason = '');
    },
    // 处理确认DCR逻辑
    handleComfirmDCR() {
      if (!this.reason) {
        this.$message.error('请填写处理结果反馈');
        return false;
      }
      const req = {
        id: this.$route.query.id,
        option: 2,
        comment: this.reason,
      };
      ExamineDcr(req)
        .then(() => {
          this.$message.success('确认成功');
          this.dcrDialog = false;
          this.dealTagQuery();
          this.getDcrDetailList();
        })
        .catch(err => {
          this.$message.error(err.message);
          this.reason = '';
        });
    },
    // 处理确认验证申请
    handleConfirmVerify() {
      const req = {
        dcr_id: this.$route.query.id,
        dcr_info: {
          dcr_type: this.detailData.type,
          dcr_data_type: this.detailData.data_type,
          data_code: this.detailData.code,
          name: this.detailData.name,
          hcp_hco_name: this.detailData.hcp_hco_name,
          hcp_city_name: this.detailData.hcp_city_name,
          hcp_dept_name: this.detailData.hcp_dept_name,
          data_detail_type: this.detailData.data_detail_type,
          delete_reason: this.detailData.delete_reason,
          content: this.tableData,
        },
      };
      AssistVerify(req)
        .then(res => {
          console.log(res);
          if (res.ret_code !== 1) {
            this.$message.success('申请成功');
            this.verifyDialog = false;
            this.dealTagQuery();
            this.getDcrDetailList();
          } else {
            this.$message.error('该数据创建时未与行业库同步，请联系平台管理员协助解决');
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },

    // pagination操作
    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      this.getDcrDetailList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      this.getDcrDetailList();
    },

    // format时间
    formatTime(val) {
      if (val && !val.startsWith('0')) {
        return dateFormat(new Date(val), 'YYYY-MM-dd HH:mm');
      }
      return '';
    },

    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(() => {
          if (this.propertyName.includes(ele.field_name) && reg.test(ele.after_value)) {
            ele.afterVal = this.getCodeLabel(ele.field_name, ele.after_value);
          }
          if (this.propertyName.includes(ele.field_name) && reg.test(ele.before_value)) {
            ele.beforeVal = this.getCodeLabel(ele.field_name, ele.before_value);
          }
          if (this.propertyName.includes(ele.field_name) && reg.test(ele.final_value)) {
            ele.finalVal = this.getCodeLabel(ele.field_name, ele.final_value);
          }
        });
      });
    },

    getCodeLabel(name, afterVal) {
      const valObj = this.configLabel.filter(item => item.property === name);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code == afterVal);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
