export default [
  {
    title: '性别',
    property: 'gender',
    value: [
      { code: '1', label: '女' },
      { code: '2', label: '男' },
      { code: '0', label: '未知' },
    ],
  },
  {
    title: 'HCP类型',
    property: 'hcp_type',
    value: [
      { code: 'N', label: '护师(士)' },
      { code: 'P', label: '药师(士)' },
      { code: 'Unknown', label: '未知' },
      { code: 'T', label: '技师(士)' },
      { code: 'D', label: '医师(士)' },
      { code: 'Other', label: '其他技术人员' },
    ],
  },
  {
    title: 'HCP状态',
    property: 'hcp_status',
    value: [
      { code: 'active', label: '有效' },
      { code: 'inactive', label: '无效' },
      { code: 'unknown', label: '未知' },
    ],
  },
  {
    title: '专业职称',
    property: 'professional_title',
    value: [
      { label: '副主任检验师', code: 'T_VCCLE' },
      { label: '美容师', code: 'O_C' },
      { label: '主管药师', code: 'P_PIC' },
      { label: '副主任医师', code: 'D_VCD' },
      { label: '副主任护师', code: 'N_VCN' },
      { label: '主任技师', code: 'T_CT' },
      { label: '主任药师', code: 'P_CP' },
      { label: '副主任技师', code: 'T_VCT' },
      { label: '技士', code: 'T_AT' },
      { label: '药士', code: 'P_AP' },
      { label: '主任护师', code: 'N_CN' },
      { label: '主任检验师', code: 'T_CCLE' },
      { label: '检验师', code: 'T_CLE' },
      { label: '主治医师', code: 'D_ATD' },
      { label: '药师', code: 'P_P' },
      { label: '营养师', code: 'O_DIET' },
      { label: '医士', code: 'D_AD' },
      { label: '统计师', code: 'O_STAT' },
      { label: '其他', code: 'Other' },
      { label: '未知', code: 'Unknown' },
      { label: '副主任药师', code: 'P_VCP' },
      { label: '康复治疗师', code: 'O_TP' },
      { label: '住院医师', code: 'D_RD' },
      { label: '技师', code: 'T_T' },
      { label: '护士', code: 'N_AN' },
      { label: '主管护师', code: 'N_NIC' },
      { label: '主任医师', code: 'D_CD' },
      { label: '主管技师', code: 'T_TIC' },
      { label: '护师', code: 'N_N' },
    ],
  },
  {
    title: '是否存疑',
    property: 'in_doubt',
    value: [
      { code: '1', label: '存疑' },
      { code: '2', label: '不存疑' },
    ],
  },
  {
    title: '数据来源',
    property: 'source',
    value: [
      { label: '默认', code: '0' },
      { label: '腾讯地图', code: '4' },
      { label: '卫健委', code: '7' },
      { label: '百度地图', code: '5' },
      { label: '高德地图', code: '3' },
      { label: '天眼查', code: '2' },
      { label: '企查查', code: '1' },
      { label: '医院', code: '6' },
      { label: 'MedMD管理端创建', code: '8' },
    ],
  },
  {
    title: '行政职务',
    property: 'administrative_title',
    value: [
      { code: 'TBD', label: '床位医生' },
      { code: 'LD', label: '带组医生' },
      { code: 'IDD', label: '住院部医生' },
      { code: 'RD', label: '进修医生' },
      { code: 'CR', label: '总住院医师' },
      { code: 'HN', label: '护士长' },
      { code: 'HND', label: '科护士长' },
      { code: 'VHND', label: '科副护士长' },
      { code: 'VWHN', label: '病区副护士长' },
      { code: 'WHN', label: '病区护士长' },
      { code: 'RN', label: '进修护士' },
      { code: 'PC', label: '负责人' },
      { code: 'TL', label: '组长' },
      { code: 'SUP', label: '主管' },
      { code: 'WO', label: '库管' },
      { code: 'P', label: '采购' },
      { code: 'S', label: '普通职员' },
      { code: 'A', label: '助理' },
      { code: 'M', label: '经理' },
      { code: 'VM', label: '副经理' },
      { code: 'HS', label: '站长' },
      { code: 'VHS', label: '副站长' },
      { code: 'OD', label: '主任' },
      { code: 'VOD', label: '副主任' },
      { code: 'AOD', label: '主任助理' },
      { code: 'BD', label: '局长' },
      { code: 'VBD', label: '副局长' },
      { code: 'SC', label: '科长' },
      { code: 'VSH', label: '副科长' },
      { code: 'DOI', label: '所长' },
      { code: 'VDI', label: '副所长' },
      { code: 'DC', label: '处长' },
      { code: 'VDC', label: '副处长' },
      { code: 'SPC', label: '书记' },
      { code: 'VSPC', label: '副书记' },
      { code: 'BSPC', label: '支部书记' },
      { code: 'ACH', label: '院长助理' },
      { code: 'CH', label: '院长' },
      { code: 'VCH', label: '副院长' },
      { code: 'OS', label: '秘书' },
      { code: 'Other', label: '其他' },
      { code: 'Unknown', label: '未知' },
    ],
  },
  {
    title: '学术头衔',
    property: 'academic_title',
    value: [
      { code: 'R', label: '研究员' },
      { code: 'AR', label: '副研究员' },
      { code: 'P', label: '教授' },
      { code: 'AP', label: '副教授' },
      { code: 'L', label: '讲师' },
      { code: 'TA', label: '助教' },
      { code: 'N', label: '无学术职称' },
      { code: 'Unknown', label: '未知' },
      { code: 'Other', label: '其它' },
    ],
  },
];
